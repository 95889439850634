import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import { useNavigate, useLocation } from 'react-router-dom';
import {serverUrl} from '../config';

const Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Retrieve token from URL parameters
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            localStorage.setItem('token', token);
            fetchProfile(token);
        } else {
            // Retrieve token from local storage
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                fetchProfile(storedToken);
            } else {
                // If no token, redirect to login
                navigate('/');
            }
        }
    }, [location.search, navigate]);

    const fetchProfile = async (token) => {
        try {
            const response = await fetch(`${serverUrl}/profile`, {
                headers: {
                    'Authorization': token
                }
            });
            if (response.status === 403) {
                localStorage.removeItem('token');
                navigate('/login');
            }
            const data = await response.json();
            setUser(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
            navigate('/');
        }
    };

    const handleSignOut = () => {
        // Clear local storage and navigate to login page
        localStorage.removeItem('token');
        localStorage.removeItem('reviews');
        setUser(null);
        navigate('/');
    
        // Refresh the page
        window.location.reload();
    };

    if (!user) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-2xl font-semibold">Loading...</div>
            </div>
        );
    }

    return (
        <>
    <div className="flex flex-col min-h-screen">
        <div className="flex justify-center items-center px-16 py-20 text-base leading-6 bg-white max-md:px-5 flex-grow">
            <div className="flex flex-col mt-10 max-w-full w-[615px] max-md:mt-10">
                <div className="text-3xl font-semibold tracking-tight leading-8 text-black max-md:max-w-full">
                    Edit profile
                </div>
                <div className="flex gap-5 justify-between self-start mt-11 max-md:mt-10">
                    <img
                        loading="lazy"
                        src={user.picture}
                        className="shrink-0 w-16 aspect-square"
                        alt='Profile'
                    />
                    <div className="flex flex-col my-auto">
                        <div className="font-medium text-black text-ellipsis">
                            {user.name}
                        </div>
                    </div>
                </div>
                <div className="mt-8 font-medium text-black text-ellipsis max-md:max-w-full">
                    Email
                </div>
                <div className="justify-center px-4 py-2 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-200 text-ellipsis max-md:max-w-full">
                    {user.email}
                </div>
                <div className="mt-8 font-medium text-black text-ellipsis max-md:max-w-full">
                    Available Credits
                </div>
                <div className="justify-center px-4 py-2 mt-2 text-black bg-white rounded-lg border border-solid border-neutral-200 text-ellipsis max-md:max-w-full">
                    {user.credits}
                </div>
                <button className="justify-center self-start px-4 py-2 mt-8 font-medium text-white bg-customBlue rounded-lg" onClick={() => navigate('/pricing')}>
                    <a href='/pricing'>Add More Credits</a>
                </button>
                <button className="justify-center self-start px-4 py-2 mt-8 font-medium text-white bg-red-600 rounded-lg" onClick={handleSignOut}>
                    Sign Out
                </button>
            </div>
        </div>
        <Footer />
    </div>
</>

    );
};

export default Profile;
