import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Footer from '../components/footer';
import pdfIcon from '../assets/pdf.png';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { serverUrl } from '../config';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import DOMPurify from 'dompurify';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Set the workerSrc property
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const loadingMessages = [
  'Analyzing',
  'Checking for Related Work',
  'Assessing Quality',
  'Providing Feedback',
  'Finalizing Review'
];

const Review = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [view, setView] = useState('uploaded'); // 'uploaded' or 'review'
  const [peerReviewContent, setPeerReviewContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [storedReviews, setStoredReviews] = useState([]); // To store the top 5 reviews from local storage
  const navigate = useNavigate();
  const [isViewingOldReview, setIsViewingOldReview] = useState(false);
  const [wordCount, setWordCount] = useState(null);

  useEffect(() => {
    const reviews = JSON.parse(localStorage.getItem('reviews')) || [];
    setStoredReviews(reviews);
  }, []);

  useEffect(() => {
    if (loading) {
      let step = 0;
      const interval = setInterval(() => {
        setLoadingStep(step);
        setProgress((step + 1) * 20);
        step++;
        if (step >= loadingMessages.length) {
          clearInterval(interval);
        }
      }, 3000);
    }
  }, [loading]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setView('uploaded');
    setPeerReviewContent(null);
    setIsViewingOldReview(false);
    extractTextFromPDF(file);
  };

  const extractTextFromPDF = (file) => {
    const reader = new FileReader();
    reader.onload = function () {
      const typedArray = new Uint8Array(this.result);
      pdfjsLib.getDocument(typedArray).promise.then(pdf => {
        let text = '';
        const promises = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          promises.push(pdf.getPage(i).then(page => {
            return page.getTextContent().then(textContent => {
              text += textContent.items.map(item => item.str).join(' ');
            });
          }));
        }
        Promise.all(promises).then(() => {
          const words = text.split(/\s+/).filter(word => word.length > 0);
          setWordCount(words.length);
          if (words.length > 10000) {
            alert('The uploaded PDF exceeds the 10,000-word limit.');
            setSelectedFile(null);
          }
        });
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const clearStoredReview = () =>{
    localStorage.removeItem('reviews');

    setStoredReviews([]);
  };

  const copyToClipboard = () =>{
    if (!peerReviewContent) {
      alert('No review content to save.');
      return;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(peerReviewContent, 'text/html');

    const text = Array.from(doc.body.childNodes)
    .map(node => {
      if (node.nodeName === 'TABLE') {
        return Array.from(node.querySelectorAll('tr'))
          .map(tr => {
            const rowText = Array.from(tr.querySelectorAll('td, th'))
              .map(cell => cell.textContent.trim()) // Trim whitespace within table cells
              .join('\t');
            return rowText;
          }).join('\n');
      }
      else{
        return node.textContent.trim()+"\n";
      }
    }).join('').trim();//(node.textContent.trim()+"\n"))//(node.nodeName === 'P' ? node.textContent + '\n\n' : node.textContent))
    

    navigator.clipboard.writeText(text);
  };
  const saveAsPDF = () => {
    if (!peerReviewContent) {
      alert('No review content to save.');
      return;
    }
  
    const doc = new jsPDF('p', 'mm', 'a4');
    const margins = { top: 10, left: 15, bottom: 10, right: 15 };
    const padding = 5;
  
    // Create an off-screen element to avoid rendering it visibly on the screen
    const element = document.createElement('div');
    element.style.padding = `${padding}mm`;
    element.style.position = 'absolute';
    element.style.top = '-9999px'; // Position the element off-screen
    element.innerHTML = `
      <div style="font-size: 22pt; line-height: 1.6;">
        ${peerReviewContent} 
      </div>
      <style>
        ul, ol {
          list-style-type: disc;
          margin-left: 20px;
        }
        .section { 
        page-break-after: always; /* Force new page after each section */
      }
      table { 
        page-break-inside: avoid; /* Avoid splitting tables across pages */
      }
      </style>
    `;
    document.body.appendChild(element);
  
    html2canvas(element, { scale: 3 }).then(canvas => {
      const imgWidth = 210 - margins.left - margins.right;
      const pageHeight = 295 - margins.top - margins.bottom;
      const imgHeight = Math.min(canvas.height * imgWidth / canvas.width, pageHeight); 
      let heightLeft = imgHeight;
      let position = margins.top;
  
      doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', margins.left, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft > 0) {
        position = heightLeft - imgHeight + margins.top;
        doc.addPage();
        doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', margins.left, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      doc.save('peer_review.pdf');
    }).catch(error => {
      console.error('Error creating PDF:', error);
      alert('An error occurred while saving the PDF.');
    }).finally(() => {
      document.body.removeChild(element); // Clean up the element after use
    });
  };
  function formatAsList(items) {
    if (!items) {
        return ''; // Return an empty string if input is null, undefined, or empty
    }

    let itemList = [];

    if (Array.isArray(items)) {
        itemList = items.map(item => item.trim());
    } else if (typeof items === 'object' && items !== null) {
        itemList = Object.values(items).map(item => item.trim());
    } else {
        // If it's a string, split by semicolons
        itemList = items.split(/;\s*/).map(item => item.trim());
    }

    itemList = itemList.filter(item => item.length > 0); // Filter out empty items

    if (itemList.length === 0) {
        return ''; // If there are no valid items, return an empty string
    }

    const listHtml = itemList
        .map(item => `<li>${item}</li>`) // Map each item to a list item element
        .join(''); // Join all list item elements into a single string without separators

    return `<ul style="list-style-type: disc; margin-left: 20px;">${listHtml}</ul>`;
}
function formatQuestionsAsList(questionsText) {
  if (!questionsText) {
      return ''; // Return an empty string if input is null, undefined, or empty
  }

  let questionList = [];

  if (Array.isArray(questionsText)) {
      questionList = questionsText.map(question => question.trim());
  } else if (typeof questionsText === 'object' && questionsText !== null) {
      questionList = Object.values(questionsText).map(question => question.trim());
  } else {
      // If it's a string, split by '?' and add back the question mark
      questionList = questionsText.split('?')
          .map(question => question.trim() + '?')
          .filter(question => question.length > 1); // Filter out empty or incomplete questions
  }

  if (questionList.length === 0) {
      return ''; // If there are no valid questions, return an empty string
  }

  const listHtml = questionList
      .map(question => `<li>${question}</li>`) // Map each question to a list item element
      .join(''); // Join all list item elements into a single string without separators

  return `<ul style="list-style-type: disc; margin-left: 20px;">${listHtml}</ul>`;
}

const handlePeerReview = async () => {
  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    handleLoginRedirect();
    return;
  }

  if (!selectedFile) {
    alert('Please upload a file first.');
    return;
  }

  setLoading(true);
  setView('review');

  setIsViewingOldReview(true);

  try {
    const response = await sendPeerReviewRequest(storedToken, selectedFile);

    if (response.status === 200 && response.data) {
      processPeerReviewResponse(response.data);
      // setSelectedFile(null);
      // handleStoredReviewClick(-1);
    } else {
      console.error('Unexpected response', response);
      alert('Unexpected response from server.');
    }
  } catch (error) {
    setIsViewingOldReview(false);
    handlePeerReviewError(error);
  } finally {
    setLoading(false);
    // setSelectedFile(null);
  }
};

const handleLoginRedirect = () => {
  setShowLoginMessage(true);
  setTimeout(() => {
    navigate('/login');
  }, 5000);
};

const sendPeerReviewRequest = (token, file) => {
  const formData = new FormData();
  formData.append('paper', file);

  return axios.post(`${serverUrl}/upload_and_review_stream`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': token,
    },
    timeout: 0,
    responseType: 'stream',
  });
};

const processPeerReviewResponse = (data) => {
  const r_data = data.split("<|>").filter(Boolean);
  const data_json = JSON.parse(r_data[r_data.length - 1])['review_result'];

  const rshow = generateReviewContent(data_json);
  saveReviewToLocal(rshow);
  setPeerReviewContent(rshow);
  setView('review');
};

const generateReviewContent = (data_json) => {
  let formattedStrengths = formatAsList(data_json['strengths']);
  let formattedWeaknesses = formatAsList(data_json['weaknesses']);
  let formattedQuestions = formatQuestionsAsList(data_json['questions']);

  return `
                <table>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Title</th>
                    <td>${data_json['title']}</td>
                </tr>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Author Names</th>
                    <td>${data_json['author names']}</td>
                </tr>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Affiliation</th>
                    <td>${data_json['affiliation']}</td>
                </tr>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Publication Year</th>
                    <td>${data_json['publication year']}</td>
                </tr>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Publisher</th>
                    <td>${data_json['publisher']}</td>
                </tr>
                <tr>
                    <th style="text-align: left; padding-right: 10px;">Language</th>
                    <td>${data_json['language']}</td>
                </tr>
            </table>
            <br>
            <b>Motivation</b><p>${data_json['motivation']}</p><br>
            <b>Summary and Contribution</b><p>${data_json['summary and contribution']}</p><br>
            <b>Methodology</b><p>${data_json['methodology']}</p><br>
            <b>Results</b><p>${data_json['results']}</p><br>
            <b>Strengths</b><p>${formattedStrengths}</p><br>
            <b>Weaknesses</b><p>${formattedWeaknesses}</p><br>
            <b>Comparison to Related Works</b><p>${data_json['comparison']}</p><br>
            <b>References to Related Works</b><p>${data_json['reference']}</p><br>
            <b>Questions</b><p>${formattedQuestions}</p><br>
  `;
};

const handlePeerReviewError = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 403:
        localStorage.removeItem('token');
        setSelectedFile(null);
        navigate('/login');
        break;
      case 503:
        localStorage.removeItem('token');
        setSelectedFile(null);
        navigate('/login');
        break;
      case 429:
        alert("You are out of funds. Please purchase more.");
        navigate('/pricing');
        break;
      case 400:
        alert("Your uploaded file was not compatible with our servers. Please try another file.");
        break;
      default:
        console.error('Peer review failed', error);
        alert('An error occurred while processing your file.');
    }
  } else {
    console.error('Peer review failed', error);
    alert('An unexpected error occurred.');
  }
};

  const saveReviewToLocal = (reviewContent) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64PDF = reader.result.split(',')[1]; // Get the base64 string after 'data:application/pdf;base64,'
      const reviews = JSON.parse(localStorage.getItem('reviews')) || [];
  
      const parser = new DOMParser();
      const doc = parser.parseFromString(reviewContent, 'text/html');
      const title = doc.querySelector('td') ? doc.querySelector('td').innerText : 'Unnamed Review';
  
      const newReview = {
        title: DOMPurify.sanitize(title),
        review: DOMPurify.sanitize(reviewContent),
        pdfName: selectedFile.name,
        pdfData: base64PDF,
        date: new Date().toISOString(),
      };
      reviews.unshift(newReview);
      localStorage.setItem('reviews', JSON.stringify(reviews.slice(0, 5))); // Store only the latest 5 reviews
      setStoredReviews(reviews.slice(0, 5)); // Update the state with the latest reviews
    };
    reader.readAsDataURL(selectedFile);
  };
  
  

  const handleStoredReviewClick = (reviewIndex) => {
    const selectedReview = storedReviews[reviewIndex];
    if (selectedReview) {
      // Set the review content
      setPeerReviewContent(selectedReview.review);
  
      // Convert the base64 PDF back to a Blob and create an object URL
      const blob = new Blob([Uint8Array.from(atob(selectedReview.pdfData), c => c.charCodeAt(0))], { type: 'application/pdf' });
      const objectURL = URL.createObjectURL(blob);
  
      // Set the PDF to be displayed
      setSelectedFile(blob);
  
      // Set flag for viewing old review
      setIsViewingOldReview(true);
  
      // Switch view to the review
      setView('review');
    }
  };
  
  
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
    setView('uploaded');
    setPeerReviewContent(null);
    setIsViewingOldReview(false);
    extractTextFromPDF(file);
  }, []);
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf',
    maxSize: 10582912, // 10MB
  });

  return (
    <div className="flex flex-col h-screen">
      <div className="flex justify-center items-center self-stretch px-16 py-20 w-full text-center bg-blue-700 text-slate-50 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col mt-5 mb-20 max-md:mb-10 max-md:max-w-full">
          <div className="text-6xl py-8 font-extrabold tracking-tight leading-[62.4px] max-md:max-w-full max-md:text-4xl">
            Review
          </div>
          <div className="self-center mt-7 text-2xl font-small leading-8 max-md:max-w-full">
            To begin, simply upload a paper to get started.
          </div>
        </div>
      </div>
      <main className="flex-grow container mx-auto bg-slate-100 border-customLime border-2 px-16 py-10 my-10 rounded-lg shadow-lg flex flex-col mt-[-60px]">
        {showLoginMessage ? (
          <div className="flex justify-center items-center">
            <div className="text-center">
              <h2 className="text-2xl font-semibold mb-4">To use this service, please login</h2>
              <p className="text-lg">You will be redirected to the login page shortly...</p>
            </div>
          </div>
        ) : (
          <div className={`grid ${selectedFile ? 'grid-cols-1 md:grid-cols-3' : 'grid-cols-1'} gap-8 flex-grow`}>
            <AnimatePresence>
              <motion.aside
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
                className={`${selectedFile ? '' : 'mx-auto'} md:sticky md:top-4`}
              >
                <div className="mt-8">
                  <p className="text-2xl font-semibold mb-2 text-blue-900">Upload Your File</p>
                  <p className="text-m text-gray-700 ">File format should be .pdf</p>
                  <p className="text-m text-gray-700 mb-4"> </p>
                  <p className="text-m text-gray-700">Maximum file size is 10MB </p>
                  <p className="text-m text-gray-700">AND</p>
                  <p className="text-m text-gray-700 mb-4">Maximum word count is 10,000 words </p>
                  <div {...getRootProps()} className={`relative bg-blue-50 rounded-lg p-10 flex flex-col items-center justify-center cursor-pointer border-dashed border-2 ${isDragActive ? 'border-blue-600' : 'border-gray-300'}`} style={{ width: '400px', height: '300px' }}>
                    <input {...getInputProps()} className="hidden" />
                    {/* <img className="w-24 h-24 mb-4" src={pdfIcon} alt="pdf" /> */}
                    {(selectedFile && !isViewingOldReview)? (
                      <>
                      <img className="w-24 h-24 mb-4" src={pdfIcon} alt="pdf" />
                      <p className="text-sm text-wrap font-semibold text-gray-700">{selectedFile.name}</p>
                      {wordCount !== null && <p className="text-sm text-gray-700">Word Count: {wordCount}</p>}
                      </>
                    ) : (
                      <>
                        <img className="w-24 h-24 mb-4" src={pdfIcon} alt="pdf" />
                        <p className="text-sm text-gray-700 text-center">Drag and drop or click to upload</p>
                      </>
                    )}
                  </div>
                  <button
                    className={`justify-center self-start px-4 py-2 mt-4 text-base font-medium text-white ${selectedFile && !isViewingOldReview ? 'bg-customBlue hover:bg-blue-500' : 'bg-gray-400 cursor-not-allowed'} rounded-lg`}
                    onClick={handlePeerReview}
                    disabled={!selectedFile || isViewingOldReview}
                  >
                    Start Reviewing
                  </button>


                  {storedReviews.length > 0 && (
                    <div className="mt-6">
                      <p className="text-lg font-semibold mb-2 text-blue-900">Previous Reviews</p>
                      <button
                            className="justify-center self-start px-4 py-2 mt-4 text-base font-medium text-white bg-customRed hover:bg-red-500 rounded-lg cursor-pointer"
                            onClick={clearStoredReview}
                          >
                            Clear stored reviews
                      </button>
                      {storedReviews.map((review, index) => (
                        <div key={index} className="mt-2">
                          <button
                            className="justify-center self-start px-4 py-2 text-base font-medium text-white bg-blue-800 hover:bg-blue-700 rounded-lg cursor-pointer"
                            style={{ width: '400px'}}
                            onClick={() => handleStoredReviewClick(index)}
                          >
                            {review.title || 'Unnamed Review'}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </motion.aside>
            </AnimatePresence>

            <AnimatePresence>
              {selectedFile && (
                <motion.section
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 0.5 }}
                  className="md:col-span-2 h-screen flex flex-col"
                >
                  <div className="flex justify-center mb-4">
                    <button
                      className={`px-4 py-2 rounded-l-lg ${view === 'uploaded' ? 'bg-customBlue text-white' : 'bg-blue-100'}`}
                      onClick={() => setView('uploaded')}
                      disabled={!selectedFile}
                    >
                      View Uploaded File
                    </button>
                    <button
                      className={`px-4 py-2 rounded-r-lg ${view === 'review' ? 'bg-customBlue text-white' : 'bg-blue-100'}`}
                      onClick={() => setView('review')}
                    >
                      View Status
                    </button>
                  </div>
                  {view === 'uploaded' && selectedFile && (
                    <div className="flex-grow border-2 logogreen-border">
                      <embed
                        src={URL.createObjectURL(selectedFile)}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  )}
                  {view === 'review' && (
                    <div className="flex-grow border p-4 flex justify-center items-start">
                      {loading ? (
                        <div className="min-h-screen flex flex-col items-center text-white">
                          <MoonLoader color="#B6D632" size={50} />
                          <div className="text-center mb-10">
                            <p className="mt-2 text-lg font-light text-gray-800">Please wait while we process your request.</p>
                          </div>
                          <div className="w-full max-w-4xl">
                            <div className="flex justify-between items-center mb-8">
                              {loadingMessages.map((task, index) => (
                                <motion.div
                                  key={index}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: index <= loadingStep ? 1 : 0.5 }}
                                  transition={{ duration: 1 }}
                                  className="flex-1 px-2"
                                >
                                  <div className={`p-4 rounded shadow-lg text-sm text-center ${index <= loadingStep ? 'bg-customBlue text-white' : 'bg-gray-800 text-gray-400'}`}>
                                    {task}
                                  </div>
                                </motion.div>
                              ))}
                            </div>
                            <div className="relative w-full h-4 bg-gray-600 rounded-full overflow-hidden mb-8">
                              <motion.div
                                className="absolute inset-0"
                                initial={{ width: 0 }}
                                animate={{ width: `${progress}%` }}
                                transition={{ duration: 1 }}
                              >
                                <div className="h-full bg-customBlue"></div>
                              </motion.div>
                            </div>
                          </div>
                        </div>
                      ) : peerReviewContent ? (
                        <div className="w-full max-w-4xl flex flex-col items-center">
                          <div className="overflow-auto whitespace-pre-line lg:max-h-[700px] md:max-h-[500px] w-full">
                            <div dangerouslySetInnerHTML={{ __html: peerReviewContent }} />
                            
                            <button
                            className="justify-center self-start px-4 py-2 mt-4 text-base font-medium text-white bg-customBlue hover:bg-blue-500 rounded-lg cursor-pointer"
                            onClick={copyToClipboard}
                            style={{ marginLeft: '0.8rem' }}
                            >
                              Copy to clipboard
                            </button>

                            <button
                            className="justify-center self-start px-4 py-2 mt-4 text-base font-medium text-white bg-customBlue hover:bg-blue-500 rounded-lg cursor-pointer"
                            onClick={saveAsPDF}
                            style={{ marginLeft: '0.8rem' }}
                            >
                              Save as PDF
                            </button>
                          </div>
                          {/* <button
                            className="justify-center self-start px-4 py-2 mt-4 text-base font-medium text-white bg-customBlue hover:bg-blue-500 rounded-lg cursor-pointer"
                            onClick={saveAsPDF}
                          >
                            Save as PDF
                          </button> */}
                        </div>
                      ) : (
                        <div className="text-center">
                          <p className="text-xl font-semibold">No review available.</p>
                          <p className="text-lg mt-2">To start the review process, please click "Start Reviewing".</p>
                        </div>
                      )}
                    </div>
                  )}
                </motion.section>
              )}
            </AnimatePresence>
          </div>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default Review;
