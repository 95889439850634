import React from 'react';
import Footer from '../components/footer';
import lp1 from '../assets/lp1.svg';
import lp2 from '../assets/lp2.svg';
import lp3 from '../assets/lp3.svg';

const Home = () => {
    return (
        <div className="scroll-smooth">
        <div className="flex h-screen justify-center items-center relative max-w-screen bg-gradient-to-r from-blue-50 to-blue-100">
          <div className="mx-auto mt-10 flex flex-col justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-800 sm:text-5xl md:text-6xl animate-fadeInDown">
                <span className="block xl:inline">
                  <span className="mb-1 block">Revolutionizing Research Paper Reviews with</span>
                  <span className="bg-gradient-to-r from-customLime to-customBlue bg-clip-text text-transparent">
                    Cutting-Edge AI Technology
                  </span>
                </span>
              </h1>
              <p className="mx-auto mt-3 max-w-xl text-xl text-black sm:mt-5 md:mt-5 animate-fadeInUp">
                Experience the power of AI to review research papers quickly, securely, and accurately. Get the best results in seconds.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center animate-fadeInUp">
                <div className="rounded-md shadow">
                  <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-customBlue px-8 py-3 text-base font-medium text-white hover:bg-blue-500 md:py-4 md:px-10 md:text-lg" href="/review">
                    Start Today 🚀
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-10 w-full flex justify-center animate-fadeInUp">
            <a href="#scroll-target">
              <svg className="w-8 h-8 animate-bounce" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </a>
          </div>
        </div>
  
        <div className="flex flex-col items-center px-5 min-h-screen justify-center" id="scroll-target">
            <div className="text-4xl font-semibold text-black">How It Works</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-20 w-full max-w-screen-xl px-4">
                {/* Section 1 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp1} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4">Transforming Academic Peer Review with AI</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800">
                    ReviewMate.ai blends cutting-edge AI with meticulous diligence of reviewers, complementing human expertise to provide objective, insightful critiques.
                </div>
                </div>

                {/* Section 2 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp2} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4 py-1">AI-Powered Review Assistance</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800 ">
                    <br></br>
                    Our AI-driven reviewer assists at every stage, providing constructive feedback and insights to enhance your research work.
                </div>
                </div>

                {/* Section 3 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp3} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4">Ensuring Document Confidentiality and Security</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800">
                    Your documents and interactions are neither stored nor used for training purposes, ensuring full confidentiality and security.
                </div>
                </div>
            </div>
            </div>



        <Footer />
      </div>
    );
};

export default Home;