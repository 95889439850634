import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import Pricing from './pages/pricing';
import Privacy from './pages/Privacypolicy';
import Accordion from './pages/faq';
import Contact from './pages/contactus';
import Login from './pages/login';
import Profile from './pages/profile';
import Review from './pages/review';
import Successful from './pages/payments/successful';
import Unsuccessful from './pages/payments/unsuccessful';
import Header from './components/header';
import Error from './pages/error';
import ContactSuccessful from './pages/contact/successful';
import ContactUnsuccessful from './pages/contact/unsuccessful';
import Terms from './pages/terms';
function App(){
  return(
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/faq" element={<Accordion />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/review" element={<Review />} />
      <Route path="/payments/successful" element={<Successful />} />
      <Route path="/payments/unsuccessful" element={<Unsuccessful />} />
      <Route path="/error" element={<Error />} />
      <Route path="contact/successful" element={<ContactSuccessful/>}/>
      <Route path="contact/unsuccessful" element={<ContactUnsuccessful/>}/>
      <Route path="/terms" element={<Terms/>}/>
      <Route path="*" element={<Error/>}/>
    </Routes>
  </BrowserRouter>
  );
};
export default App;
// reportWebVitals();
