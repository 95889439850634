import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import { serverUrl } from '../config';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedProfileImage = localStorage.getItem('profileImage');

    if (token) {
      setIsLoggedIn(true);
      if (storedProfileImage) {
        setProfileImage(storedProfileImage);
      } else {
        fetchProfileImage(token);
      }
    }
  }, []);

  const fetchProfileImage = async (token) => {
    try {
      const response = await fetch(`${serverUrl}/profile`, {
        headers: {
          'Authorization': token,
        },
      });
      if (response.status === 403) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/login');
      } else {
        const data = await response.json();
        setProfileImage(data.picture);
        localStorage.setItem('profileImage', data.picture);
      }
    } catch (error) {
      console.error('Error fetching profile image:', error);
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      navigate('/login');
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="w-full fixed top-0 left-0 right-0 bg-white z-50 border-b border-customBlue">
      <nav className="w-full flex justify-between items-center p-4">
        {/* Left: Logo and Title */}
        <div className="flex items-center pl-5">
          <a href="/" className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-9 mr-2" />
            <span className="text-lg font-bold bg-customBlue bg-clip-text text-transparent">ReviewMate.AI</span>
          </a>
        </div>

        {/* Middle: Menu Options */}
        <div className="hidden md:flex space-x-6 items-center text-black text-lg">
          <a href="/" className={`${location.pathname === '/' ? 'border-b-2 border-customLime ' : ''}`}>Home</a>
          <a href="/review" className={`${location.pathname === '/review' ? 'border-b-2 border-customLime' : ''}`}>Review</a>
          <a href="/faq" className={`${location.pathname === '/faq' ? 'border-b-2 border-customLime' : ''}`}>FAQ</a>
          <a href="/pricing" className={`${location.pathname === '/pricing' ? 'border-b-2 border-customLime' : ''}`}>Pricing</a>
          <a href="/contact" className={`${location.pathname === '/contact' ? 'border-b-2 border-customLime' : ''}`}>Contact</a>
        </div>

        {/* Right: Profile and Try Now */}
        <div className="flex items-center pr-5 space-x-4">
          <div className="hidden md:block">
            {isLoggedIn ? (
              <button className="relative" onClick={() => navigate('/profile')}>
                <img src={profileImage} alt="Profile" className="h-8 w-8 rounded-full object-cover" />
              </button>
            ) : (
              <button className="bg-customBlue text-white px-4 py-2 rounded hover:bg-blue-500" onClick={() => { navigate('/login'); toggleMenu(); }}>
                Try Now!
              </button>
            )}
          </div>
          <button className="md:hidden text-black" onClick={toggleMenu}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </nav>
      {menuOpen && (
        <div className="md:hidden flex flex-col items-center justify-center text-black space-y-4 p-4 bg-white">
          <a href="/" onClick={toggleMenu} className={`${location.pathname === '/' ? 'border-b-2 border-customBlue' : ''}`}>Home</a>
          <a href="/review" onClick={toggleMenu} className={`${location.pathname === '/review' ? 'border-b-2 border-customBlue' : ''}`}>Review</a>
          <a href="/faq" onClick={toggleMenu} className={`${location.pathname === '/faq' ? 'border-b-2 border-customBlue' : ''}`}>FAQ</a>
          <a href="/pricing" onClick={toggleMenu} className={`${location.pathname === '/pricing' ? 'border-b-2 border-customBlue' : ''}`}>Pricing</a>
          <a href="/contact" onClick={toggleMenu} className={`${location.pathname === '/contact' ? 'border-b-2 border-customBlue' : ''}`}>Contact</a>
          {isLoggedIn ? (
            <button className="relative" onClick={() => navigate('/profile')}>
              <img src={profileImage} alt="Profile" className="h-8 w-8 rounded-full object-cover" />
            </button>
          ) : (
            <button className="bg-customBlue text-white px-4 py-2 rounded hover:bg-blue-500" onClick={() => navigate('/login')}>
              Try Now!
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
